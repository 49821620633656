const SOFTWARE_TYPE = [
  { name: "All", value: null },
  { name: "Revit", value: "Revit" },
  { name: "Navisworks", value: "Navisworks" },
  { name: "Drone", value: "Drone" },
  { name: "Synchro", value: "Synchro" },
]

const SITEVIEW_TYPE = [
  { name: "All", value: null },
  { name: "iTwin", value: "iTwin" },
  { name: "Cesium", value: "Cesium" },
  { name: "Forge", value: "Forge" },
]

export default {
  projectName: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  projectId: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  software: {
    component: "SelectSearchFilter",
    attributes: {
      items: SOFTWARE_TYPE,
      value: null,
    },
    offline: true,
  },
  type: {
    component: "SelectSearchFilter",
    attributes: {
      items: SITEVIEW_TYPE,
      value: null,
    },
    offline: true,
  },
}
