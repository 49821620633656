<template>
  <v-dialog v-model="editingDialog" width="500">
    <v-card>
      <v-card-title>
        <span>Edit Site view</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="siteView.projectName"
                    disabled
                    dense
                    outlined
                    :error-messages="errors"
                    label="Project name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="siteView.projectId"
                    disabled
                    dense
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Viewer Type"
                  rules="required"
                >
                  <v-select
                    v-model="siteView.viewerType"
                    :items="types"
                    label="Viewer Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ERow v-if="siteView.viewerType == 'iTwin'" class="mx-0">
                <ECol cols="12" class="pt-0 pb-0">
                  <v-select
                    v-model="selectedITwinModelName"
                    outlined
                    dense
                    label="Available Models"
                    :items="siteView.iTwinModels"
                    item-text="name"
                    item-value="name"
                  />
                </ECol>
                <ECol cols="12" class="py-0">
                  <ECol v-if="selectedITwinObject" cols="12" class="py-0">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="iTwin ID"
                      rules="required"
                    >
                      <v-text-field
                        v-model="selectedITwinObject.iTwinId"
                        outlined
                        :error-messages="errors"
                        dense
                        label="iTwinID"
                        clearable
                      />
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="iModel ID"
                      rules="required"
                    >
                      <v-text-field
                        v-model="selectedITwinObject.iModelId"
                        outlined
                        :error-messages="errors"
                        dense
                        label="iModelID"
                        clearable
                      />
                    </ValidationProvider>
                  </ECol>
                </ECol>
              </ERow>
              <ERow v-if="siteView.viewerType == 'Forge'" class="mx-0">
                <ECol cols="12" class="pt-0 pb-0">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="URN"
                    rules="required"
                  >
                    <v-text-field
                      v-model="siteView.urn"
                      dense
                      outlined
                      hide-details
                      :error-messages="errors"
                      label="Autodesk URN"
                    />
                  </ValidationProvider>
                  <v-checkbox
                    v-model="siteView.isEvercamAccount"
                    class="mb-4 ml-3"
                    hide-details
                  >
                    <template #label>
                      <span class="caption">
                        Using evercam autodesk account
                      </span>
                    </template>
                  </v-checkbox>
                </ECol>
              </ERow>
              <ECol cols="12" class="pt-0 pb-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Software types"
                  rules="required"
                >
                  <v-select
                    v-model="siteView.softwareType"
                    :items="softwareTypes"
                    label="Software types"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
            </ERow>
          </v-form>
          <h3 v-if="siteView.viewerType == 'iTwin'" class="red--text ml-3">
            Danger Zone
          </h3>
          <div
            v-if="siteView.viewerType == 'iTwin'"
            id="dangerZone"
            class="my-3"
          >
            <ERow class="pa-3" justify="around">
              <v-btn outlined color="red" @click="clearBimCompare">
                <v-icon left>fa-trash</v-icon
                ><span class="subtitle-2"> BIM Compare </span>
              </v-btn>
              <v-btn
                outlined
                color="red"
                :disabled="
                  !selectedITwinModelName ||
                  isSiteViewBeingCleared ||
                  !siteView.iTwinModels
                "
                @click="clearSiteView"
              >
                <v-icon v-if="!isSiteViewBeingCleared" small left
                  >fa-trash</v-icon
                >
                <v-progress-circular
                  v-else
                  left
                  indeterminate
                  color="red"
                  :size="14"
                  :width="2"
                  class="mr-2"
                ></v-progress-circular>
                <span class="subtitle-2"> BIM </span>
              </v-btn>
            </ERow>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', null)">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="invalid"
            @click="editSiteview()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      siteView: {
        projectId: null,
        softwareType: null,
        viewerType: null,
        projectName: null,
        height: null,
        iTwinModels: null,
        urn: null,
        isEvercamAccount: true,
      },
      types: ["iTwin", "Cesium", "Forge"],
      softwareTypes: ["Navisworks", "Revit", "Synchro", "Drone"],
      selectedITwinModelName: null,
      selectedITwinObject: null,
      isSiteViewBeingCleared: false,
    }
  },
  computed: {
    editingDialog: {
      get() {
        return this.value
      },
      set(value) {
        if (!value) {
          this.$emit("input", null)
        }
      },
    },
    selectedITwinModelIndex() {
      return this.siteView.iTwinModels.findIndex(
        (item) => item?.name === this.selectedITwinModelName
      )
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.siteView.projectId = value.projectId
        this.siteView.viewerType = value.type
        this.siteView.softwareType = value.software
        this.siteView.projectName = value.projectName
        this.siteView.urn = value.urn
        this.selectedITwinModelName = null
        this.selectedITwinObject = null
        this.getItemData(value)
      }
    },
    selectedITwinModelName(val) {
      this.selectedITwinObject = this.siteView.iTwinModels.find(
        (item) => item.name === val
      )
    },
  },
  methods: {
    async getItemData(value) {
      if (value.type === "Cesium") {
        this.siteView.iTwinModels = null
        let response = await fetch(
          this.$config.public.firebaseDbLink +
            `data/projects/${value.type.toLowerCase()}/` +
            value.projectId +
            "/transform.json"
        )
        let res = await response.json()
        this.siteView.pointSize = res?.pointSize
      } else if (value.type === "iTwin") {
        const response = await fetch(
          this.$config.public.firebaseDbLink +
            `data/projects/itwin/${this.siteView.projectId}.json`
        )
        const data = await response.json()
        this.siteView.iTwinModels = data.models.map((item) => {
          if (!item.name) {
            item.name = "Empty name"
          }

          return item
        })
        this.selectedITwinModelName = this.siteView.iTwinModels[0].name
      } else if (value.type === "Forge") {
        const response = await fetch(
          this.$config.public.firebaseDbLink +
            `data/projects/forge/${this.siteView.projectId}.json`
        )
        const data = await response.json()
        this.siteView.urn = data.urn
        this.siteView.isEvercamAccount = data.isEvercamAccount
      }
    },
    async editSiteview() {
      let siteView = {
        projectName: this.siteView.projectName,
        software: this.siteView.softwareType,
        type: this.siteView.viewerType,
        projectId: this.siteView.projectId,
        uniqueId: `${this.siteView.projectId}${this.siteView.viewerType}`,
        trueIndex: this.value.trueIndex,
        iTwinModels: this.siteView.iTwinModels,
      }
      let itemData = {}
      if (siteView.type === "iTwin") {
        itemData = {
          models: this.siteView.iTwinModels,
        }
      } else if (siteView.type === "Forge") {
        itemData = {
          urn: this.siteView.urn,
          isEvercamAccount: this.siteView.isEvercamAccount,
        }
      }
      this.$emit("edit-items", {
        siteView: siteView,
        itemData: itemData,
        initialViewer: this.value.type,
      })
      this.$emit("input", null)
    },
    async clearBimCompare() {
      fetch(
        this.$config.public.firebaseDbLink +
          `data/projects/itwin/${this.siteView.projectId}/bim-compare.json`,
        {
          method: "DELETE",
        }
      ).then(() => {
        this.$notifications.success("BIM compare view successfully cleared")
      })
    },
    async clearSiteView() {
      try {
        this.isSiteViewBeingCleared = true
        await fetch(
          this.$config.public.firebaseDbLink +
            `data/projects/itwin/${this.siteView.projectId}/models/${this.selectedITwinModelIndex}/site-view.json`,
          {
            method: "DELETE",
          }
        )
        this.$notifications.success(
          `Saved view of ${this.selectedITwinModelName} model successfully cleared!`
        )
      } catch (error) {
        this.$notifications.error(
          `Error while clearing saved view of ${this.selectedITwinModelName} model: ${error}`
        )
      } finally {
        this.isSiteViewBeingCleared = false
      }
    },
  },
}
</script>

<style>
#dangerZone {
  border-style: dotted;
  border-radius: 5px;
  border-width: 2px;
  border-color: red;
}
</style>
