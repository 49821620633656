export default [
  {
    value: "edit",
    text: "",
    filterable: false,
  },
  {
    value: "projectName",
    text: "Project Name",
    sortable: true,
  },
  {
    value: "projectId",
    text: "ID",
    width: 150,
    sortable: true,
  },
  {
    value: "missingExids",
    text: "Missing Cameras",
    sortable: false,
  },
  {
    value: "software",
    text: "Software Type",
    sortable: true,
    width: 150,
  },
  {
    value: "type",
    text: "Viewer Type",
    width: 150,
  },
  {
    value: "delete",
    text: "",
    sortable: true,
  },
]
