var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReportTable',{attrs:{"name":"siteView","item-key":"trueIndex","headers":_vm.headers,"provider":_vm.fetchBimCameras,"data-field":"data","process-items":_vm.processItems,"filter-fields":_vm.filterFields,"copy-to-clipboard":false,"hide-show":false},scopedSlots:_vm._u([{key:"actions-left",fn:function(){return [_c('div',{staticClass:"body-1 text--secondary my-3"},[_vm._v("Site view")])]},proxy:true},{key:"actions-right",fn:function(){return [_c('v-btn',{staticClass:"mr-1",attrs:{"loading":_vm.isCopyLoading,"color":"primary","dark":"","small":""},on:{"click":_vm.openCopyDatabaseDialog}},[_vm._v("\n      Copy Database\n    ")]),_vm._v(" "),_c('AddSiteViewDialog',{attrs:{"getBIMCameras":_vm.refreshTable}}),_vm._v(" "),_c('EditSiteViewDialog',{on:{"edit-items":_vm.editItems},model:{value:(_vm.editedItem),callback:function ($$v) {_vm.editedItem=$$v},expression:"editedItem"}})]},proxy:true},{key:"item.edit",fn:function({ item }){return [_c('ERow',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":"","target":"_blank"},on:{"click":function($event){_vm.editedItem = item}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" fa-pencil-alt ")])],1)],1)]}},{key:"item.projectName",fn:function({ item }){return [_vm._v("\n    "+_vm._s(item.projectName)+"\n    "),_c('OpenInNewWindowButton',{attrs:{"target-link":`${_vm.$config.public.dashURL}/projects/${item.projectId}/${
        item.type === 'Cesium' ? 'drone' : 'bim'
      }`}})]}},{key:"item.projectId",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('nuxt-link',{attrs:{"to":{
              path: `/site-view/${item.projectId}`,
              query: { type: item.type },
            }}},[_c('span',{staticClass:"cursor-pointer primary--text"},[_vm._v(_vm._s(item.projectId)+"\n            ")])])],1)]}}],null,true)},[_vm._v(" "),_c('span',[(item.type === 'iTwin')?_c('v-img',{attrs:{"src":`https://firebasestorage.googleapis.com/v0/b/bim-three-vue.appspot.com/o/${
            item.projectId
          }-${item.type.toLowerCase()}.png?alt=media&token=${
            _vm.$config.public.LOAD_ITWIN_THUMBNAIL_TOKEN
          }`,"aspect-ratio":"1.4","width":"285"}}):_vm._e()],1),_vm._v(" "),_c('span',[_vm._v(" "+_vm._s(item.projectName)+" | "+_vm._s(item.software))])])]}},{key:"item.missingExids",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((item.missingExids),function(exid,index){return _c('span',{key:exid},[_c('v-chip',{class:{ 'mr-1': index < item.missingExids.length - 1 },attrs:{"small":"","rounded":"","outlined":"","color":"primary"}},[_c('CopyToClipboardBtn',{attrs:{"text":exid,"icon":"","color":"primary"}}),_vm._v("\n          "+_vm._s(exid)+"\n        ")],1)],1)}),0)]}},{key:"item.delete",fn:function({ item }){return [_c('ERow',{attrs:{"justify":"center","align":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","x-small":"","target":"_blank"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" fas fa-trash ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }